<template>
  <v-card>
    <v-card-title>Waste Reports</v-card-title>
    <v-card-text>
      <v-fade-transition>
        <v-alert
          v-show="actionAlert"
          border="left"
          color="success"
          dark
          text
          dismissible
        >
          {{ actionAlertMessage }}
        </v-alert>
      </v-fade-transition>
    </v-card-text>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        placeholder="Search Control Number"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="fetchWasteReports"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="$store.getters['auth/can'](['create waste reports'])"
          color="primary"
          class="mb-4 me-3"
          @click="create"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Waste Report</span>
        </v-btn>

        <!-- <v-btn
          color="secondary"
          outlined
          disabled
          class="mb-4"
        >
          <v-icon
            size="17"
            class="me-1"
          >
            {{ icons.mdiExportVariant }}
          </v-icon>
          <span>Export</span>
        </v-btn> -->
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="wasteReports"
        :options.sync="options"
        :server-items-length="totalWasteReports"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- id -->
        <template #[`item.id`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              v-if="item.job_order_id"
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'job-orders-edit', params: { id: item.job_order_id } }"
            >
              #{{ item.id }}
            </router-link>
          </div>
        </template>

        <!-- report_date  -->
        <template #[`item.report_date`]="{item}">
          <span>{{ resolveDate(item.report_date) }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="$store.getters['auth/can'](['edit waste reports'])"
                @click="editItem(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item :to="{ name: 'waste-reports-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                  <span>View</span>
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item
                v-if="$store.getters['auth/can'](['delete waste reports'])"
                @click="deleteItemConfirm(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="dialogDelete = !dialogDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItem"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCarPickup,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import useWasteReports from './useWasteReports'
import router from '@/router'
import useUIResolver from '../useUIResolver'

const defaultAlertMessage = 'WasteReport successfully saved.'

export default {
  setup() {
    const wasteMaterialData = ref({})
    const actionAlert = ref(false)
    const actionAlertMessage = ref('')
    const searchQuery = ref('')
    const totalWasteReports = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dialogDelete = ref(false)
    const headers = ref([])

    const { wasteReports, getWasteReports } = useWasteReports()
    const { wasteReportTableHeaders, resolveDate } = useUIResolver()

    const fetchWasteReports = () => {
      const params = { ...options.value, ...{ searchQuery: searchQuery.value } }
      getWasteReports(params).then(response => {
        totalWasteReports.value = response.data.total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    onMounted(() => {
      actionAlertMessage.value = defaultAlertMessage
      headers.value = wasteReportTableHeaders()
    })

    const clearSearch = () => {
      searchQuery.value = null
      fetchWasteReports()
    }

    const create = () => {
      router.push({ name: 'waste-reports-create' })
    }

    const saveWasteReport = () => {
      actionAlertMessage.value = defaultAlertMessage
      actionAlert.value = true
      fetchWasteReports()
    }

    const editItem = item => {
      router.push({ name: 'waste-reports-edit', params: { id: item.id } })
    }

    const deleteItem = () => {
      axios.delete(`/waste-reports/${wasteMaterialData.value.id}`).then(response => {
        actionAlertMessage.value = 'WasteReport successfully deleted.'
        actionAlert.value = true

        // hide confirm alert
        dialogDelete.value = false
        fetchWasteReports()
      })
    }

    const deleteItemConfirm = item => {
      wasteMaterialData.value = item
      dialogDelete.value = true
    }

    watch(options, fetchWasteReports)

    watch(actionAlert, val => {
      if (val) {
        setTimeout(() => {
          actionAlert.value = false
        }, 3000)
      }
    })

    return {
      dialogDelete,
      actionAlert,
      actionAlertMessage,
      wasteMaterialData,
      headers,
      searchQuery,
      totalWasteReports,
      footerProps,
      options,
      resolveDate,

      // usWasteReports
      wasteReports,
      getWasteReports,

      // functions
      clearSearch,
      create,
      saveWasteReport,
      fetchWasteReports,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCarPickup,
      },
    }
  },
}
</script>
